
<template>
  <div>
    <page-header title='Seleção de Inscritos'></page-header>
    <v-row justify="end" class="mr-4 mb-3" v-if="['active', 'selecting'].includes(course.status) && user.role.name != 'coordinator'">
      <v-btn 
        color="white" 
        depressed 
        rounded class="mx-2"
        @click="$router.push({name: 'NewManualEnrollment', params:{course_id: course.id }})"
      ><v-icon size="26" color="primary" left>mdi-plus-circle-outline</v-icon> 
        NOVO INSCRITO MANUAL
      </v-btn>
    </v-row>
    <v-card style="border-radius:12px" flat outlined>
      <v-card-title primary-title class='primary white--text py-1' >
        <div class="d-flex flex-column">
          <div class="text-subtitle-2">Curso</div>
          <div style="word-break: break-word">{{course.name}}</div>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column pb-2">
          <div class="text-subtitle-2 d-flex justify-end">
            Status: {{course_status_map[course.status]}}
            <course-details-dialog class="ml-2" :course="course"></course-details-dialog>
          </div>
          <div v-if="!loading">
            <!-- ['done_selection',''].includes(course.status) && has_lost_enrolled -->
            <v-row class="mt-3 pb-3">
              <v-tooltip top color='black' v-if="['done_selection'].includes(course.status)">
                <template v-slot:activator="{on}">
                  <v-btn 
                    v-on="on" 
                    fab 
                    x-small 
                    class="elevation-0 mr-2" 
                    max-width='28' 
                    max-height='28' 
                    color="white" 
                    @click="changeCourseStatus(course.status, -1)"
                  ><v-icon>mdi-undo</v-icon></v-btn>
                </template>
                Voltar à etapa de seleção
              </v-tooltip>
              <BtnCreateDataExport 
                class="mx-1 mt-n1 pb-3"
                api_model="DoneForm"
                action="DoneForms"
                :filters="{course_id: $route.params.id, exec_time: 'enrollment'}"
                :file_name="'Inscrição - ' + course.name.substr(0,30)"
              ></BtnCreateDataExport>

              <v-btn v-if="['done'].includes(course.status)" color="success" small @click="$router.push({name: 'StudentRating'})" rounded>
                <v-icon left>mdi-keyboard-tab</v-icon> Ver avaliações 
              </v-btn>
              <v-btn 
                v-if="(user.role.name != 'coordinator' && !['in_progress', 'done'].includes(course.status))" 
                color="success" 
                small 
                @click="changeCourseStatus(course.status, +1)" 
                rounded
                :disabled="!can_start_course && course.status == 'done_selection'"
              > {{adv_status_map[course.status].title}} <v-icon right>mdi-check</v-icon></v-btn>
              <v-btn color="success" class="mx-2" small rounded 
                v-if="['in_progress'].includes(course.status)" 
                :to="({name: 'StudentRating', params: {id: course.id}})"
              >Avaliar alunos</v-btn>

            </v-row>
          </div>
        </div>
      </v-card-title>
      <card-loading-transparent v-if="loading"></card-loading-transparent>
      <v-card-text class="px-0 pb-3" v-show="!loading">
        <course-chairs ref='course_chairs_comp' :course="course" :countries="countries"></course-chairs>
        <v-divider></v-divider>
        <list-candidates 
          v-if="!loading && ['active', 'selecting'].includes(course.status)" 
          :course='course' 
          :done_forms="done_forms" 
          @doneChangeDoneFormStatus="handleSetDoneFormStatus"
        ></list-candidates>
        <list-enrolleds 
          v-if="!loading && !['active', 'selecting'].includes(course.status)" 
          :course='course' 
          :enrolleds="enrolleds"
        ></list-enrolleds>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import Api from '@/api/index'
import { mapActions, mapState, mapGetters } from 'vuex'
import CourseChairs from '../candidate_select_done_forms/select_components/CourseChairs.vue'
import ListCandidates from '../candidate_select_done_forms/select_components/ListCandidates.vue'
import ListEnrolleds from '../candidate_select_done_forms/select_components/ListEnrolleds.vue'
import CourseDetailsDialog from '../shared/CourseDetailsDialog.vue'

export default {
  name: 'CandidateSelectIndex',
  components: {
    CourseChairs,
    ListCandidates,
    ListEnrolleds,
    CourseDetailsDialog,
    BtnCreateDataExport: () => import('@/components/data_export/BtnCreateDataExport.vue'),
  },
  data() {
    return {
      search: '',
      loading: true,
      course_status_map:{
        active: 'Ativo', 
        selecting: 'Selecionando', 
        done_selection: 'Seleção Pronta', 
        in_progress: 'Em Progresso', 
        done: 'Conluido', 
        suspended: 'cancelado'
      },
      adv_status_map:{
        active: { title: 'Iniciar seleção', next_status: 'selecting' }, 
        selecting: { title: 'Finalizar seleção!', next_status: 'done_selection' }, 
        done_selection: { title: 'Iniciar curso', next_status: 'in_progress' }, 
      },
      headers: [
        { text: '#', value: 'id'},
        { text: 'Aluno', value: 'name'},
        { text: 'E-mail', value: 'email' },
        { text: 'Estado', value: 'state' },
        { text: 'País', value: 'country' },
        { text: 'Idade', value: 'birthdate' },
        //{ text: 'Instituição', value: 'institution' },
        { text: 'Status', value: 'status', align: 'center'},
        { text: 'Formulário', value: 'actions', align: 'center', sortable: false},
      ],
      countries_map:{ 
        brazilian: 'Brasil', 
        argentine: 'Argentina', 
        colombian: 'Colombia', 
        uruguayan: 'Uruguai', 
        paraguayan: 'Paraguai', 
      },
      chairs_count: {},
      course: {},
      done_forms: [],
      enrolleds: [],


      dialog_course_details: false,
      total_chairs_homologated: 0,
    }
  },
  methods: {
    changeCourseStatus(status, advance_or_regress){
      let arr = Object.keys(this.course_status_map)
      let idx = arr.findIndex( el => el == status )
      let next_status_to_set = arr[idx + advance_or_regress]
      
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      }).then((r) => { 
        if(r.isConfirmed){
          let course = this.prepareCourseStatus(this.course, next_status_to_set)
          this.updateCourseStatus(course)
        }
      })
    },
    updateCourseStatus(course){
      Api.Course.update(course).then(()=>{
        this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
        this.course.status = course.status
        this.getInfos()
      }).catch(err => {
        this.addNotification({type: 'error', msg: `Erro: ${err}`})
      })
    },
    prepareCourseStatus(item, status){
      let course = {
        id: item.id,
        status: status
      }
      return course
    },
    
    handleSetDoneFormStatus({value, id}){

      let idx = this.done_forms.findIndex(df=> df.id == id)
      this.done_forms[idx].status = value

      this.recalculateChairs()
    },
    getCourseInfo(){
      Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=>{
        this.course = d
        if (['active', 'selecting'].includes(this.course.status)){
          this.getDoneForms()
        } else {
          this.getEnrolleds()
        }
      })
    },
    getDoneForms(){
      Api.DoneForm.index({course_id: this.$route.params.id}).then(r=>r.data).then((d)=>{
        this.done_forms = d
        this.recalculateChairs()
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    getEnrolleds(){
      Api.Enrolled.index({course_id: this.$route.params.id}).then(r=>r.data).then((d)=>{
        this.enrolleds = d
        this.recalculateChairs()
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500);
      })
    },
    recalculateChairs(){
      setTimeout(() => {
        this.$refs.course_chairs_comp.countChairs()
      }, 50);
    },
    getInfos(){
      this.loading = true
      this.getCourseInfo();
      //this.getDoneForms();
    },
    
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  mounted () {
    this.getInfos()
  },
  computed: {
    comp_done_forms(){
      if (this.done_forms.length){
        if (['done_selection', 'in_progress', 'done'].includes(this.course.status)){
          return this.done_forms.filter(df=>df.status == 'homologated')
        }
      }
      return this.done_forms
    },
    homologated(){
      return this.done_forms.filter(df=> df.status == 'homologated')
    },
    confirmed(){
      return this.enrolleds.filter(df=> df.status == 'confirmed')
    },
    countries(){
      if (this.done_forms.length || this.enrolleds.length){
        let data = (this.homologated.length > 0 && this.homologated) 
        data ||= (this.confirmed.length > 0 && this.confirmed)
        let country_map = data && data.map(df=> df.country)

        return country_map || []
        //return data && data.map(df=> df.country)
      }
      return []
    },
    enrolled_count_by_status(){
      if (this.enrolleds.length){
        return this.enrolleds.map(({status})=>status).reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));
      }
      return null
    },
    has_lost_enrolled(){
      if (this.enrolleds.length){
        return this.enrolled_count_by_status.canceled > 0 || this.enrolled_count_by_status.missed > 0
      }
      return null
    },
    can_start_course(){
      if (this.enrolleds.length){
        return !this.enrolled_count_by_status.pending 
      }
      return false
    },
    ...mapState({
      user: state=> state.User.user
    })

    
  },
}
</script>

<style scoped>

</style>