
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items-per-page="10"
      :items="comp_done_forms"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small text color="primary" @click="doneFormDialog(item)" rounded outlined>
          <v-icon left>mdi-clipboard-text</v-icon>VER
        </v-btn>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ $dayjs().diff($dayjs(item.birthdate).format('YYYY-MM-DD'), 'year' )}} anos
      </template>

      <template v-slot:[`item.country`]="{ item }">
        {{ countries_map[item.country] != null ? countries_map[item.country] : item.country }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip small :color="status_chips_map[item.status].color">
          <v-icon small class="mr-2">{{status_chips_map[item.status].icon}}</v-icon>{{status_chips_map[item.status].title}} 
        </v-chip>
      </template>
    </v-data-table>

    <dialog-enrollment-done-form
      v-if="dialog"
      :dialog="dialog" 
      :selected_done_form="selected_done_form"
      :course_status="course.status"
      @closeDialog="handleCloseDialog" 
      @setDoneFormStatus="handleSetDoneFormStatus"
    />

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'ListCandidates',
  props: {
    course: {
      type: Object,
    },
    done_forms:{
      type: Array,
    }
  },
  components: {
    DialogEnrollmentDoneForm: () => import ('../select_components/DialogEnrollmentDoneForm.vue'),
  },
  data() {
    return {
      // done_forms: [],
      dialog: false,

      selected_done_form: null,
      

      search: '',
      headers: [
        {text: '#', value: 'id'},
        {text: 'Aluno', value: 'name'},
        { text: 'E-mail', value: 'email' },
        { text: 'Estado', value: 'state' },
        { text: 'País', value: 'country' },
        { text: 'Idade', value: 'birthdate' },
        //{ text: 'Instituição', value: 'institution' },
        { text: 'Status', value: 'status', align: 'center'},
        { text: 'Formulário', value: 'actions', align: 'center', sortable: false},
      ],

      countries_map:{ 
        brazilian: 'Brasil', 
        argentine: 'Argentina', 
        colombian: 'Colombia', 
        uruguayan: 'Uruguai', 
        paraguayan: 'Paraguai', 
        other: 'Outro', 
      },

      status_chips_map: {
        fresh: { color: 'primary', title: 'Novo', icon: 'mdi-account-plus' },
        selected_on_hold: { color: 'primary', title: 'Em espera', icon: 'mdi-clock-outline' },
        selected_on_hold_1: { color: 'primary', title: 'Em espera 1', icon: 'mdi-clock-outline' },
        selected_on_hold_2: { color: 'primary', title: 'Em espera 2', icon: 'mdi-clock-outline' },
        selected_on_hold_3: { color: 'primary', title: 'Em espera 3', icon: 'mdi-clock-outline' },
        selected: { color: 'success', title: 'Selecionado', icon: 'mdi-check' },
        rejected: { color: 'error', title: 'Indeferido', icon: 'mdi-cancel' },
        homologated: { color: 'success', title: 'Homologado', icon: 'mdi-star' },
      },
    }
  },
  methods: {
    handleCloseDialog(){

      this.dialog = false
    },
    handleSetDoneFormStatus({value, id}){
      this.dialog = false


      this.$emit('doneChangeDoneFormStatus',{value, id})
    },
    doneFormDialog(selected_done_form){
      this.selected_done_form = selected_done_form
      this.dialog = true
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    comp_done_forms(){
      if (this.done_forms.length){
        if (['done_selection', 'in_progress', 'done'].includes(this.course.status)){
          return this.done_forms.filter(df=>df.status == 'homologated')
        }
      }
      return this.done_forms
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>