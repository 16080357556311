
<template>
  <div>
    <div class="mt-2 ml-3">
      <div class="text-h6" style="white-space: wrap;">Total de cadeiras <span style="white-space: nowrap;">{{['active', 'selecting'].includes(course.status) ? 'homologadas' : 'confirmadas'}} : ({{total_chairs_homologated}} / {{course.total_chairs}}) </span></div>
    </div>
    <div class="text-center" v-if="Object.keys(course).length > 0">
      <v-chip class="ma-2" outlined>
        <country-flag country="br" size="normal" class="mb-1"></country-flag>
        <span class="text-subtitle-2 px-2 py-3">Brasil: {{chairs_count.brazilian}} / {{course.json_attributes.chairs.brazilian}}</span>
      </v-chip>
      <v-chip class="ma-2" outlined>
        <country-flag country="ar" size="normal" class="mb-1"></country-flag>
        <span class="text-subtitle-2 px-2 py-3">Argentina: {{chairs_count.argentine}} / {{course.json_attributes.chairs.argentine}}</span>
      </v-chip>
      <v-chip class="ma-2" outlined>
        <country-flag country="col" size="normal" class="mb-1"></country-flag>
        <span class="text-subtitle-2 px-2 py-3">Colombia: {{chairs_count.colombian}} / {{course.json_attributes.chairs.colombian}}</span>
      </v-chip>
      <v-chip class="ma-2" outlined>
        <country-flag country="uy" size="normal" class="mb-1"></country-flag>
        <span class="text-subtitle-2 px-2 py-3">Uruguai: {{chairs_count.uruguayan}} / {{course.json_attributes.chairs.uruguayan}}</span>
      </v-chip>
      <v-chip class="ma-2" outlined>
        <country-flag country="py" size="normal" class="mb-1"></country-flag>
        <span class="text-subtitle-2 px-2 py-3">Paraguai: {{chairs_count.paraguayan}} / {{course.json_attributes.chairs.paraguayan}}</span>
      </v-chip>
      <v-chip class="ma-2" outlined>
        <v-icon>mdi-diving-scuba-flag</v-icon>
        <span class="text-subtitle-2 px-2 py-3">Outros: {{chairs_count.other}} / {{course.json_attributes.chairs.other}}</span>
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'CourseChairs',
  props: {
    course: {
      type: Object,
    },
    countries:{
      type: Array
    }
  },
  data() {
    return {
      total_chairs_homologated: 0,
      chairs_count: {},

    }
  },
  methods: {
    countChairs(){
      const {chairs} = this.course.json_attributes

      let arr = Object.keys(chairs)

      let default_chairs = arr.reduce((a, v) => ({ ...a, [v]: 0}), {})
      this.total_chairs_homologated = 0

      for (let idx = 0; idx < this.countries.length; idx++) {
        const country = this.countries[idx];
        default_chairs[country]++
        this.total_chairs_homologated++
      }

      this.chairs_count = default_chairs
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>