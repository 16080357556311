
<template>
  <div>
    <v-btn fab x-small class="elevation-0" max-width='20' max-height='20' color="white" @click="showCourseDetails()">
      <v-icon>mdi-information-variant</v-icon>
    </v-btn>
    
    <dialog-show-course-details
      :dialog="dialog_course_details"
      :course="course"
      @closeDialog="handleCloseDialogCourseDetails"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'CourseDetailsDialog',
  props: {
    course: {
      type: Object,
      default: null
    }
  },
  components: {
    DialogShowCourseDetails: () => import ('../candidate_select_done_forms/select_components/DialogShowCourseDetails.vue'),
  },
  data() {
    return {
      dialog_course_details: false
    }
  },
  methods: {
    showCourseDetails(){
      this.dialog_course_details = true
    },
    handleCloseDialogCourseDetails(){
      this.dialog_course_details = false
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>