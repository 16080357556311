
<template>
  <div>
    <v-data-table
      :headers="headers"
      :items-per-page="10"
      :items="comp_enrolleds"
      :search="search"
    >
      <template v-slot:top>
        <v-text-field  v-model="search" label="Buscar" class="mx-4"></v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn small text color="primary" @click="openDialogAndShowDoneFormAnswers(item)" rounded outlined>
          <v-icon left>mdi-clipboard-text</v-icon>VER
        </v-btn>
      </template>

      <template v-slot:[`item.birthdate`]="{ item }">
        {{ $dayjs().diff($dayjs(item.birthdate).format('YYYY-MM-DD'), 'year' )}} anos
      </template>

      <template v-slot:[`item.country`]="{ item }">
        {{ countries_map[item.country] != null ? countries_map[item.country] : item.country }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-row>
          <v-col cols="12" class="pa-0 pt-1 mt-4">
            <v-chip small :color="status_chips_map[item.status].color">
              <v-icon small class="mr-2">{{status_chips_map[item.status].icon}}</v-icon>{{status_chips_map[item.status].title}} 
            </v-chip>
          </v-col>
          <v-col cols="12" class="pa-0 pt-1 mb-5">
            <v-btn v-if="item.status == 'missed'"  small text color="primary" @click="manualEnrollConfirm(item)" rounded outlined >
              <v-icon left>mdi-clipboard-text</v-icon> CONFIRMAR MANUALMENTE
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <dialog-enrollment-done-form
      v-if="dialog_one_enrolled"
      :dialog="dialog_one_enrolled" 
      :selected_done_form="enrolled"
      :course_status="course.status"
      @closeDialog="handleCloseDialog" 
      @setDoneFormStatus="handleSetDoneFormStatus"
    />

    <dialog-loading loadingText="Salvando" v-if="saving"></dialog-loading>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'ListEnrolleds',
  props: {
    course: {
      type: Object,
    },
    enrolleds:{
      type: Array,
    }
  },
  components: {
    DialogEnrollmentDoneForm: () => import ('../select_components/DialogEnrollmentDoneForm.vue'),
  },
  data() {
    return {
      // enrolleds: [],

      dialog_one_enrolled: false,

      status_chips_map: {
        // {pending: 1, confirmed: 10, canceled: 20, missed: 30}
        pending: { color: 'primary', title: 'Aguardando', icon: 'mdi-account-plus' },
        missed: { color: 'error', title: 'Esqueceu', icon: 'mdi-alert' },
        canceled: { color: 'error', title: 'Cancelado', icon: 'mdi-cancel' },
        confirmed: { color: 'success', title: 'Confirmado', icon: 'mdi-star' },
      },


      search: '',
      headers: [
        {text: '#', value: 'id'},
        {text: 'Aluno', value: 'name'},
        { text: 'E-mail', value: 'email' },
        // { text: 'Estado', value: 'state' },
        { text: 'País', value: 'country' },
        { text: 'Idade', value: 'birthdate' },
        //{ text: 'Instituição', value: 'institution' },
        { text: 'Status', value: 'status', align: 'center'},
        
        { text: 'Formulário da inscrição', value: 'actions', align: 'center', sortable: false},
      ],

      countries_map:{ 
        brazilian: 'Brasil', 
        argentine: 'Argentina', 
        colombian: 'Colombia', 
        uruguayan: 'Uruguai', 
        paraguayan: 'Paraguai', 
        other: 'Outro', 
      },
      enrolled: {},
      saving: false,
    }
  },
  methods: {
    handleCloseDialog(){
      this.enrolled = {}
      this.dialog_one_enrolled = false
    },
    handleSetDoneFormStatus({value, id}){
      this.dialog_one_enrolled = false

      this.$emit('doneChangeDoneFormStatus',{value, id})
    },
    openDialogAndShowDoneFormAnswers(enrolled){
      this.enrolled = {...enrolled}
      this.dialog_one_enrolled = true
    },
    manualEnrollConfirm(item){
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
        title: 'Deseja confirmar manualmente a inscrição? A ação é irreversível.',
        icon: 'warning',
      }).then(( r )=>{
        if(r.isConfirmed) {

          let enrolled = { status: 'confirmed', id: item.id }

          this.saving = true
          Api.Enrolled.update(enrolled).then(r=>r.data).then(d=> {
            this.enrolleds.map(el=>{
              if(el.id == d.id){
                return el.status = d.status
              }
            })
            this.addNotification({type: 'success', msg: `Inscrição confirmada com sucesso!`})
          }).catch((err)=>{
            this.addNotification({type: 'error', msg: `Ops, ocorreu um erro...`})
          }).finally(()=> {
            this.saving = false
          })
        }
      })
    },  
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  computed: {
    comp_enrolleds(){
      if (this.enrolleds.length){
        if ( false && ['done_selection', 'in_progress', 'done'].includes(this.course.status)){
          return this.enrolleds.filter(df=>df.status == 'homologated')
        }
      }
      return this.enrolleds
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>